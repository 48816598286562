<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备特征码">
          <el-input v-model="searchForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入设备特征码" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" icon="el-icon-search" @click="initTable">查询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增设备</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="content">
      <el-table :data="waterList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="equipmentCode" label="设备序列号" show-overflow-tooltip width="200">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.createTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.updateTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button size="mini" @click="deleteById(scope.row.id)" type="danger" icon="el-icon-delete" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      waterList: [],
      waterId: 0, // 单位ID
      waterForm: {
        waterName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        policePhone: '',
        remark: ''
      }, // 建筑表单
      waterFormCopy: {},
      searchForm: {}
    }
  },
  created () {
    this.initTable()
    this.waterFormCopy = JSON.stringify(this.waterForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.searchForm.equipmentCode) {
        data.equipmentCode = this.searchForm.equipmentCode
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/willUploadEquipment/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.waterList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除设备
    async deleteById (id) {
      const { data: result } = await this.$axios.delete(`/willUploadEquipment/del/${id}`)
      if (result.code === 200) {
        this.$message.success('删除成功')
        const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
        this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
        this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
        this.initTable()
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
.drawer-form {
  font-size: 0;
  padding: 10px 40px;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
      float: left;
    }
    ::v-deep .el-form-item__content {
      float: left;
    }
  }
}
::v-deep  :focus {
  outline: 0;
}
</style>
